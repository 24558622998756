import React from "react";
import images from "../../images/hero.jpg";
import {
  HeroContainer,
  HeroBg,
  ImgBg,
  HeroContent,
  HeroP,
  HeroCard,
  HeroTwin,
  HeroH2,
  HeroSpan,
} from "./HeroElements";

const HeroSection = () => {
  return (
    <HeroContainer id="home">
      <HeroBg>
        <ImgBg autoPlay loop muted src={images} />
      </HeroBg>

      <HeroContent>
        {/* <HeroH1>Welcome</HeroH1> */}
        <HeroP>
          เมื่อเห็นแล้วว่าทุกสิ่งจะต้องสลายไปเช่นนี้
          พวกท่านควรจะเป็นคนแบบไหนในการดำเนินชีวิตที่บริสุทธิ์และที่ยำเกรงพระเจ้า...2
          เปโตร 3:11 <br />
          <br />
          ยินดีต้อนรับเข้าสู่คริสตจักรบ้านพระคุณกรุงเทพ ที่ๆเราเลือกพระเจ้า และ
          เลือกที่จะดำเนินชีวิตในทางของพระองค์ เป็นทางแคบที่มีชีวิต
          ที่ๆไม่มีใครแย่งชิงจิตวิญญาณของเราไปได้...เชิญเข้ามาค้น..เคาะ...หา
          แล้วท่านจะพบว่าชีวิตที่อัศจรรย์ที่มีพระเจ้าเคียงข้าง
          จะพาเราเผชิญและผ่านทุกสิ่งในชีวิตและมีสันติสุขกับพระองค์
        </HeroP>

        <HeroCard>

          <HeroTwin>
            <HeroH2>รอบเทศนาวันพุธ</HeroH2>
            <HeroSpan>เริ่ม 19.00 น.</HeroSpan>
          </HeroTwin>

          <HeroTwin>
            <HeroH2>รอบเทศนาวันอาทิตย์</HeroH2>
            <HeroSpan>เริ่ม 10.30 น.</HeroSpan>
          </HeroTwin>

        </HeroCard>

      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
