import styled from "styled-components";

export const ContactContainer = styled.div`
  padding: 40px 100px;
  background: #fff;

  @media screen and (max-width: 812px) {
    padding: 40px 20px;
  }
`;

export const IframeContainer = styled.iframe`
    border-width: 0px;
`;
