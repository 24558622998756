import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const VideoWrapper = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 56.25%;
  height: 0;
`;

const Iframe = styled.iframe`
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const YoutubeEmbed = ({ embedId }) => (
  <VideoWrapper>
    <Iframe
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </VideoWrapper>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
