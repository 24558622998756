import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

export const Nav = styled.nav`
  /* background: ${({ scrollNav }) => (scrollNav ? "#fff" : "transparent")}; */
  box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.35);
  box-shadow: ${({ scrollNav }) => (scrollNav ? "0px 2px 6px 1px rgba(0,0,0,0.35)" : "0px 0px 0px 0px rgba(0,0,0,0)")};
  background: #f9f9f9;
  height: ${({ scrollNav }) => (scrollNav ? "60px" : "80px")};
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  transition: 0.8s all ease;
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1650px;
`;

export const NavLogo = styled(LinkR)`
  color: #000;
  justify-self: flex-start;
  cursor: pointer;
  font-size: ${({ scrollNav }) => (scrollNav ? "1.4rem" : "1.8rem")};
  transition: 0.8s all ease;
  display: flex;
  align-items: center;
  // margin-left: 24px;
  font-weight: 800;
  text-decoration: none;

  @media screen and (max-width: 420px) {
    font-size: 1.2rem;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 854px) {
    /* display: block; */
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 0%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #000;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;

  @media screen and (max-width: 854px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 60px;
`;

export const NavLinks = styled(LinkS)`
  color: #000;
  font-weight: 400;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  

  &.active {
    border-bottom: 3px solid rgb(30, 151, 191);
  }
`;

// export const NavBtn = styled.nav`
//   display: flex;
//   align-items: center;

//   @media screen and (max-width: 768px) {
//     display: none;
//   }
// `;

// export const NavBtnLink = styled(LinkR)`
//   border-radius: 50px;
//   background: transparent;
//   border: solid 2px #fff;
//   white-space: nowrap;
//   padding: 10px 22px;
//   margin: 0 1rem;
//   color: #fff;
//   font-size: 16px;
//   outline: none;
//   cursor: pointer;
//   transition: all 0.2s ease-in-out;
//   text-decoration: none;

//   &:hover {
//     transition: all 0.2s ease-in-out;
//     background: #fff;
//     color: #2d9fc0;
//   }
// `;
