import React, {useState} from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import HeroSection from '../components/HeroSection'
import InfoSection from '../components/InfoSection'
import { homeObjOne } from '../components/InfoSection/Data'
import LeaderSection from '../components/LeaderSection'
import Footer from '../components/Footer'
import Whatwebelieve from '../components/WhatwebelieveSection'
import ContactSection from '../components/ContactSection'

const Home = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    };


    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <HeroSection />
            <InfoSection {...homeObjOne} />
            <Whatwebelieve />           
            <LeaderSection />
            <ContactSection />
            <Footer />
        </>
    );
};

export default Home
