import styled from "styled-components";

export const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 100vh;
  position: relative;
  z-index: 1;

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: rgb(66 66 66 / 43%); */
    background: rgb(30, 151, 191);
    /* background: linear-gradient(
      174deg,
      rgba(30, 151, 191, 0.938813025210084) 0%,
      rgba(33, 223, 215, 0.4066001400560224) 60%,
      rgba(0, 194, 255, 0.3393732492997199) 100%
    ); */
    background: rgb(87, 158, 255);
    background: linear-gradient(
      119deg,
      rgba(87, 158, 255, 0.7763480392156863) 28%,
      rgba(195, 53, 0, 0.6222864145658263) 66%,
      rgba(0, 0, 0, 0.6530987394957983) 92%
    );
    z-index: 2;
  }

  @media screen and (max-width: 400px) {
    height: 140vh;
  }
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

// export const VideoBg = styled.video`
//   width: 100%;
//   height: 100%;
//   -o-object-fit: cover;
//   object-fit: cover;
//   background: #232a34;
// `;

export const ImgBg = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #0009; */
`;

// export const HeroH1 = styled.h1`
//   color: #fff;
//   font-size: 48px;
//   text-align: center;

//   @media screen and (max-width: 768px) {
//     font-size: 40px;
//   }

//   @media screen and (max-width: 480px) {
//     font-size: 32px;
//   }
// `;

export const HeroP = styled.p`
  /* margin-top: 24px; */
  color: #fff;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  max-width: 1000px;
  line-height: 1.7em;
  padding: 10px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const HeroCard = styled.div`
  background-color: #fff;

  max-width: 1200px;

  width: 100%;
  border-radius: 7px;
  padding: 15px 0px;
  margin: 5% auto;

  display: flex;
  justify-content: space-around;
`;

export const HeroTwin = styled.div`
  border-right: 1px solid #cecece;
  padding: 10px 10%;
  flex: 1;
`;
export const HeroH2 = styled.h2`
  font-size: 16px;
  color: #03a9f4;
`;

export const HeroSpan = styled.span`
  color: #8e8e8e;
`;
