import React from "react";
import { FaYoutube } from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  FooterLinkNew,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRight,
  SocialIcons,
  SocialIconLink,
} from "./FooterElements";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>เกี่ยวกับเรา</FooterLinkTitle>
              <FooterLink
                to="aboutus"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-60}
              >
                พันธกิจของเราทำอะไร?
              </FooterLink>
              <FooterLink
                to="whatwebelieve"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-60}
              >
                หลักข้อเชื่อของเรา
              </FooterLink>
              <FooterLink
                to="leadership"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-60}
              >
                ผู้นำคริสตจักร
              </FooterLink>
            </FooterLinkItems>

            <FooterLinkItems>
              <FooterLinkTitle>หลักข้อเชื่อ</FooterLinkTitle>
              <FooterLink
                to="whatwebelieve"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-60}
              >
                พระคริสตธรรมคัมภีร์
              </FooterLink>
              <FooterLink
                to="whatwebelieve"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-60}
              >
                พระเจ้า
              </FooterLink>
              <FooterLink
                to="whatwebelieve"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-60}
              >
                พระเยซูคริสต์
              </FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>

          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>หลักข้อเชื่อ</FooterLinkTitle>
              <FooterLink
                to="whatwebelieve"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-60}
              >
                พระวิญญาณบริสุทธิ์
              </FooterLink>
              <FooterLink
                to="whatwebelieve"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-60}
              >
                ความรอด
              </FooterLink>
              <FooterLink
                to="whatwebelieve"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-60}
              >
                คริสตจักร
              </FooterLink>
            </FooterLinkItems>

            <FooterLinkItems>
              <FooterLinkTitle>ติดต่อเรา</FooterLinkTitle>
              <FooterLinkNew href="tel:+66641077802">
                064 107 7802
              </FooterLinkNew>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo>GGBK</SocialLogo>
            <WebsiteRight>
              Greater Grace Bangkok © {new Date().getFullYear()} All rights
              reserved.
            </WebsiteRight>
            <SocialIcons>
              <SocialIconLink
                href="https://www.youtube.com/channel/UCKMltXbgdfJZr_SnYXP64-A"
                target="_blank"
                aria-label="Youtube"
              >
                <FaYoutube />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
