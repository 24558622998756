import React from "react";
import { Button } from "../BottonElements";
import YoutubeEmbed from "../YoutubeEmbed";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  Column2,
  ImgWrap,
  Img,
} from "./InfoElements";

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  heading,
  lightTextDesc,
  description,
  buttonLabel,
  img,
  embedId,
  alt,
}) => {

  return (
    <>

      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{heading}</Heading>
                <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>

                {(() => {
                  if (buttonLabel !== "null") {
                    return (
                      <BtnWrap>
                        <Button>{buttonLabel}</Button>
                      </BtnWrap>
                    );
                  }
                })()}
              </TextWrapper>
            </Column1>
            <Column2>
              {(() => {
                if (img === "null") {
                  return <YoutubeEmbed embedId={embedId} />;
                } else {
                  return (
                    <ImgWrap>
                      <Img src={img} alt={alt} />
                    </ImgWrap>
                  );
                }
              })()}
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
