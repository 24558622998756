import React from "react";
import { ContactContainer, IframeContainer } from "./ContactElements";

const ContactSection = () => {
  return (
    <ContactContainer id="contactus">
      <IframeContainer
        width="100%"
        height="400"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
        id="gmap_canvas"
        src="https://maps.google.com/maps?q=%E0%B8%9A%E0%B9%89%E0%B8%B2%E0%B8%99%E0%B8%9E%E0%B8%A3%E0%B8%B0%E0%B8%84%E0%B8%B8%E0%B8%93%E0%B8%81%E0%B8%A3%E0%B8%B8%E0%B8%87%E0%B9%80%E0%B8%97%E0%B8%9E%20%E0%B8%8B%E0%B8%AD%E0%B8%A2%20%E0%B8%A3%E0%B8%B2%E0%B8%A1%E0%B8%84%E0%B8%B3%E0%B9%81%E0%B8%AB%E0%B8%87%2024%20%E0%B9%81%E0%B8%A2%E0%B8%81%2034%20%E0%B9%81%E0%B8%82%E0%B8%A7%E0%B8%87%20%E0%B8%AB%E0%B8%B1%E0%B8%A7%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%81%20%E0%B9%80%E0%B8%82%E0%B8%95%E0%B8%9A%E0%B8%B2%E0%B8%87%E0%B8%81%E0%B8%B0%E0%B8%9B%E0%B8%B4%20%E0%B8%81%E0%B8%A3%E0%B8%B8%E0%B8%87%E0%B9%80%E0%B8%97%E0%B8%9E%E0%B8%A1%E0%B8%AB%E0%B8%B2%E0%B8%99%E0%B8%84%E0%B8%A3%2010240&t=&z=13&ie=UTF8&iwloc=&output=embed"
      ></IframeContainer>{" "}
      
      <script
        type="text/javascript"
        src="https://embedmaps.com/google-maps-authorization/script.js?id=ba17bc6dd88a5f6f6c7e1a8bbaeb0f1b56c3ae61"
      ></script>
    </ContactContainer>
  );
};

export default ContactSection;