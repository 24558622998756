import styled from "styled-components";

export const LeaderContainer = styled.div`
  background: #f9f9f9;
`;

export const LeaderH1 = styled.h1`
  text-align: center;
  font-size: 48px;
  padding: 50px 0;
`;

export const Hr = styled.hr`
  width: 90%;
  margin: auto;
  border: 1px solid #dadada;
`;
