import React from "react";

import {
  BelieveContainer,
  BelieveH1,
  BelieveWrapper,
  BelieveCard,
  BelieveH2,
  BelieveP,
} from "./WhatwebelieveElements";

const Whatwebelieve = () => {
  return (
    <>
      <BelieveContainer id="whatwebelieve">
        <BelieveH1>หลักข้อเชื่อของเรา</BelieveH1>

        <BelieveWrapper>
          <BelieveCard>
            <BelieveH2>เกี่ยวกับพระคริสตธรรมคัมภีร์</BelieveH2>
            <BelieveP>
              เราเชื่อว่าพระคัมภีร์ทั้งภาคพันธสัญญาเก่าและใหม่ทั้งในทุกส่วนทั้ง
              เหตุกาณ์ ถ้อยคำ ถูกดลใจโดยพระเจ้า
              และเต็มไปด้วยสิทธิอำนาจเพื่อช่วยเหลือ สั่งสอน หนุนใจ
              ผู้เชื่อในความเข้าใจเกี่ยวกับชีวิตและพันธกิจ
            </BelieveP>
            
          </BelieveCard>

          <BelieveCard>
            <BelieveH2>เกี่ยวกับพระเจ้า</BelieveH2>
            <BelieveP>
              มีเพียงพระเจ้าแท้องนิรันดร์กาล ใน 3 พระภาค คือ พระบิดา พระบุตร
              และพระวิญญาณบริสุทธิ์
              รวมเป็นหนึ่งเดียวกันและทรงอานุภาพในการทำงานร่วมกัน
            </BelieveP>
            
          </BelieveCard>

          <BelieveCard>
            <BelieveH2>เกี่ยวกับพระเยซูคริสต์</BelieveH2>
            <BelieveP>
              เราเชื่อว่าพระคริสต์เป็นพระบุตรของพระเจ้า
              พระองค์ทรงเป็นสภาพพระเจ้า และทรงสภาพมนุษย์
              บังเกิดโดยพระวิญญาณบริสุทธิ์ผ่านทางครรภ์ของนางมารีย์
              ใช้ชีวิตในสภาพของมนุษย์โดยปราศจากบาป พระองค์สิ้นพระชนม์ หลั่งโลหิต
              เพื่อคนบาป
              และถูกฝั่งไว้ในอุโมงค์และฟื้นพระชนม์และกลับไปยังสู่ทรงสวรรค์
              และนั่งอยู่บนสวรรค์สถานที่พระหัตถ์ขวาของพระบิดา
            </BelieveP>
            
          </BelieveCard>

          <BelieveCard>
            <BelieveH2>เกี่ยวกับพระวิญญาณบริสุทธิ์</BelieveH2>
            <BelieveP>
              พระวิญญาณบริสุทธ์ คือ องค์ที่ปรึกษา ทำงานร่วมกับพระบิดา และพระบุตร
              ในฐานะเป็นพระเจ้าภาคที่ 3
              และการสำแดงการงานของพระเจ้าในแผนงานที่พระบิดาได้ดำริ
              พระบุตรได้กระทำ และพระวิญญาณเปิดเผย ถึงความบาป
              ความชอบธรรมและการพิพากษา
            </BelieveP>
            
          </BelieveCard>

          <BelieveCard>
            <BelieveH2>เกี่ยวกับความรอด</BelieveH2>
            <BelieveP>
              ความรอดเป็นของขวัญจากพระเจ้า
              เป็นการงานที่เสร็จสิ้นสมบูรณ์โดยพระคริสต์บนไม้กางเขน
              ผู้ใดที่ต้อนรับพระคริสต์เข้ามาจะได้ทำให้เป็นผู้ชอบธรรมเพราะการงานของความเชื่อไม่ใช่เพราะการกระทำ
              และความรอดเป็นพระคุณผ่านทางความเชื่อ
              ไม่สามารถสูญเสียได้เพราะไม่ได้เป็นการงานของมนุษย์
            </BelieveP>
            
          </BelieveCard>

          <BelieveCard>
            <BelieveH2>เกี่ยวคริสตจักร</BelieveH2>
            <BelieveP>
              คริสตจักรเป็นกายของพระคริสต์และเป็นเจ้าสาวของพระองค์
              คริสตจักรของพระองค์เต็มไปด้วยการนมัสการ และรับใช้การงานของพระเจ้า
              และเข้าส่วนในการสั่งสอนสาวก โดยมีศิษยาภิบาล ครูผู้สอน
              ที่ได้รับการแต่งตั้งจากการยอมจำนนในการอยู่ภายใต้การศึกษาพระคัมภีร์
              ถูกสอนในหลักธรรมอันบริสุทธิ์ และชีวิตแห่งการใช้ความเชื่อ
            </BelieveP>
            
          </BelieveCard>

        </BelieveWrapper>
      </BelieveContainer>
    </>
  );
};
export default Whatwebelieve;
