import styled from "styled-components";
import { BsPlusSquareFill } from "react-icons/bs";

export const BelieveContainer = styled.div`
  padding: 50px 30px;
  background: rgb(142, 206, 255);
  background: linear-gradient(
    174deg,
    rgba(142, 206, 255, 1) 0%,
    rgba(110, 219, 245, 1) 60%,
    rgba(142, 206, 255, 1) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BelieveH1 = styled.h1`
  text-align: center;
  font-size: 48px;
  color: #fff;

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const BelieveWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  max-width: 1600px;
  margin-top: 30px;
  

  @media screen and (max-width: 742px) {
    font-size: 16px;
    grid-template-columns: 1fr;
  }
`;

export const BelieveCard = styled.div`
  /* display: flex;

  justify-content: space-between; */
  background: #fff;
  padding: 20px 30px;
  border-radius: 5px;
`;

export const BelieveH2 = styled.h2`
  color: #2d9fc0;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 10px;

  display: flex;
  align-items: center;
`;

export const BelieveP = styled.p`
  font-size: 18px;
  font-weight: 300;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
`;

export const PlusIcon = styled(BsPlusSquareFill)`
  cursor: pointer;
  font-size: 20px;
  color: #2d9fc0;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: #2388a5;
  }
`;
