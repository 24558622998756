import styled from 'styled-components'

export const InfoContainer = styled.div`
    color: #fff;
    background: ${({ lightBg }) => (lightBg ? '#f9f9f9' : '#010606')};
    
`;

export const InfoWrapper = styled.div`
    /* display: grid; */
    z-index: 1;
    /* height: 100vh; */
    width: 100%;
    max-width: 1400px;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 0px;
    justify-content: center;
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    /* grid-template-columns: 1fr 1fr; */
    grid-gap: 40px;
    align-items: center;
    grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};
    padding: 40px 50px;

    @media screen and (max-width: 878px) {       
        grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2' 'col1'` : `'col1 col1' 'col2 col2'`)};
    }

    @media screen and (max-width: 480px) {
        padding: 40px 25px;
        grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2' 'col1'` : `'col1 col1' 'col2 col2'`)};
    }
`;

export const Column1 = styled.div`
    /* padding: 0 15px; */
    grid-area: col1;
`;

export const Column2 = styled.div`
    /* margin-bottom: 15px; */
    /* padding: 15px; */
    grid-area: col2;
    /* margin: auto; */
    /* height: 100%; */
`;

export const TextWrapper = styled.div`
    /* max-width: 540px; */
    /* padding: 0 30px; */
    /* padding-bottom: 60px; */

    @media screen and (max-width: 878px) {
        /* padding-bottom: 60px; */
    }

    @media screen and (max-width: 583px) {
        padding: 0;
    }
`;

export const TopLine = styled.p`
    color: #5EC2EE;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const Subtitle = styled.p`
    /* max-width: 440px; */
    /* margin-bottom: 35px; */
    font-size: 18px;
    line-height: 1.6em;
    color: ${({ lightTextDesc }) => (lightTextDesc ? '#fff' : "#010606")};
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const ImgWrap = styled.div`
    max-width: 600px;
    height: 100%;
    margin: auto;

    @media screen and (max-width: 878px) {
        padding-bottom: 20px;
    }
`;

export const Img = styled.img`
    width: 100%;
    /* margin: 0 0 10px 0; */
    padding-right: 0;

    @media screen and (max-width: 1028px) and (min-width: 878px) {
        max-width: 400px;
    }
`;