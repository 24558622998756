import React from "react";
import InfoSection from "../InfoSection";
import { homeObjTwo } from "../InfoSection/Data";
import { homeObjThree } from "../InfoSection/Data";
import { LeaderContainer, Hr } from "./LeaderElements";

const LeaderSection = () => {
  return (
    <LeaderContainer id="leadership">
      <InfoSection {...homeObjTwo} />
      <Hr/>
      <InfoSection {...homeObjThree} />
      
    </LeaderContainer>
  );
};

export default LeaderSection;
