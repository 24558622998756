import styled from 'styled-components'
import { Link } from 'react-scroll'

export const Button = styled(Link)`
    border-radius: 30px;
    background: ${({ primary }) => (primary ? '#5EC2EE' : 'rgba(255,255,255,0)')};
    white-space: nowrap;
    padding: ${(big) => (big ? '14px 48px' : '12px 30px')};
    color: ${({ dark }) => (dark ? '#010606' : '#5EC2EE')};
    font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: solid 1px #5EC2EE;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-ease-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({ primary }) => (primary ? '#fff' : '#5EC2EE')};
        color: ${({ dark }) => (dark ? '#5EC2EE' : '#fff')};
    }
`;